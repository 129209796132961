.LoginPage {
  display: grid;
  grid-template-columns: 66% 34%;
  min-height: 100vh;

  .left-panel,
  .right-panel {
    height: 100%;
  }
  
  .back-btn {
    position: absolute;
    top: 30px;
    left: 30px;
    border: 1px solid #A9B3BD;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%;
    
    .back-btn-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .left-panel {
    background: #FFFFFF;
    padding: 164px 30px 30px 70px;

    display: flex;
    flex-direction: column;

    .left-panel-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #424242;
    }

    .input-fields {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      max-width: 400px;
      flex: 1;

      .mobile-label {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #57565A;
      }

      .input-fields-phone {
        display: flex;
        align-items: center;
        margin-top: 28px;
      }

      .login-mobile-input,
      .login-email-input {
        background: #FFFFFF;
        border: 1px solid #BDBDBD;
        box-sizing: border-box;
        border-radius: 6px;
      }
      
      .login-mobile-input {
        margin: 0 0 0 14px;
      }

      .or-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #BFBFBF;
        margin: 14px auto;
      }
    }
    
    .login-btn {
      background: #F31208;
      border-radius: 6px;
      max-width: 420px;
      color: white;
      text-transform: inherit;

      &.disabled {
        background: #47494C;
        color: #909193;

        &:hover, &:active {
          transform: none;
          box-shadow: none;
        }
      }
    }
  }

  .right-panel {
    background: #F1F1F1;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .right-panel-logo {
      width: 60%;
      object-fit: contain;
      margin: 0 auto;
    }

    .right-panel-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #424242;
      margin: 50px 0 20px;
    }

    .business-owner {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 80px;
      }

      &:nth-child(n + 2) {
        flex: 1; 
      }

      .owner-title {
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        color: #424242;
      }

      .owner-options {
        list-style-type: disc;
        padding-left: 30px;

        li {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #424242;
          margin: 10px 0;
        }
      }

      .owner-access-icon {
        width: 20px;
        object-fit: contain;
        margin-right: 12px;
      }

      .owner-access-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        background: #F31208;
        border: 1px solid #F31208;
        box-sizing: border-box;
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;
        color: #FFFFFF;

        margin: 20px auto 0;
        width: 100%;
        text-transform: inherit;

        &.outline {
          background: #FFFFFF;
          border: 1px solid #F31208;
          font-weight: 500;
          color: #F31208;
        }
      }
    }
  }

  @include respond(tab-port) {
    display: grid;
    grid-template-columns: 60% 40%;

    .left-panel {
      padding: 122px 30px 20px;

      .input-fields {
        max-width: 370px;
      }
  
      .login-btn {
        max-width: 380px;
      }
    }

    .right-panel {
      .business-owner {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }

  @include respond(phone) {
    display: flex;
    flex-direction: column;

    .left-panel {
      padding: 100px 20px 20px;

      .input-fields {
        width: 100%;
        margin-top: 28px;

        .input-fields-phone {
          display: flex;
          align-items: center;
          margin-top: 28px;

          .text-input {
            flex: 1;
            margin: 0 0 0 14px;
          }
        }

        .login-mobile-input,
        .login-email-input {
          width: 100%;
          margin: 10px 0 0;
        }
      }

      .login-btn {
        width: 100%;
        margin: 10px auto 0;
      }  
    }
    
    .right-panel {
      padding: 24px 16px 24px;

      .right-panel-title {
        margin: 36px 0;
        text-align: center;
      }

      .business-owner {
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
          margin-top: 15px;
        }

        &:nth-child(n + 2) {
          flex: unset; 
        }
      }
    }
  }
}