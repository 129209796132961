.text-input {
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 14px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #757575;
}