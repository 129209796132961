*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5% (16 is default browser font size)

  @include respond(tab-land) {
    font-size: 56.25%; // 1 rem = 9px; 9/16 = 50%
  }

  @include respond(tab-port) {
    font-size: 50%; // 1 rem = 8px; 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1 rem = 12px; 12/16 = 75%
  }
}

body {
  box-sizing: border-box;

  @include respond(tab-port) {
    padding: 0;
  }
}

// ::selection {
//   background-color: $color-primary;
//   color: $color-white;
// }
