.section-stories {
  background-image: url("../../assets/images/world.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  flex-direction: column;
  padding: 10rem 15rem;

  .stories-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
  }

  .stories-sub-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: #000000;
    margin: 38px 0 48px;
  }

  .stories-faq-items {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .stories-faq-item {
      display: flex;
      flex-direction: column;
      padding: 18px 0;
      border-bottom: 1px solid #C4C4C4;
      
      &:nth-child(even) {
        margin-left: 10px;
      }

      &:nth-child(odd) {
        margin-right: 10px;
      }
      
      &:nth-child(1), &:nth-child(2) {
        border-top: 1px solid #C4C4C4;
      }

      .faq-item-header {
        display: flex;
        justify-content: space-between;

        .faq-item-title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
        }

        .faq-item-icon {
          margin-left: 4px;
          width: 20px;

          &.open {
            transform: rotate(180deg);
          }

          &.close {
            transform: rotate(0deg);
          }
        }
      }
      
      .faq-item-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #424242;
        margin-top: 21px;
      }
    }
  }

  @include respond(tab-port) {
    padding: 48px 16px;
    background-position: center;

    .stories-faq-items {
      display: flex;
      flex-direction: column;

      .stories-faq-item {
        padding: 14px 0;
        margin: 0;

        &:nth-child(1) {
          border-top: 1px solid #C4C4C4;
        }
  
        &:nth-child(2) {
          border-top: none;
        }
  
        &:first-child {
          margin: 0;
        }
        
        &:not(:first-child) {
          margin: 10px 0 0;
        }
      }
    }
  }

  @include respond(phone) {
    padding: 48px 16px;
    background-position: center;

    .stories-title {
      font-size: 14px;
    }

    .stories-sub-title {
      font-size: 20px;
      margin: 20px 0 30px;
    }

    .stories-faq-items {
      display: flex;
      flex-direction: column;

      &:nth-child(1) {
        border-top: 1px solid #C4C4C4;
      }

      &:nth-child(2) {
        border-top: none;
      }

      .stories-faq-item {
        padding: 14px 0;
        margin: 0;

        &:first-child {
          margin: 0;
        }
        
        &:not(:first-child) {
          margin: 10px 0 0;
        }
      }
    }
  }
}
