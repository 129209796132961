.section-features {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 15rem;

  .feature-logo {
    width: 340px;
    object-fit: contain;
    margin: 0 auto;
  }

  .feature-items {
    display: flex;
    flex-direction: row;
    margin-top: 68px;
    
    .feature-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px 15px;
      max-width: 200px;

      .feature-item-icon {
        width: 48px;
        height: 48px;
        object-fit: contain;
      }

      .feature-item-title {
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 120%;
        color: #000000;
        margin: 24px 0 18px;
        text-align: center;
      }

      .feature-item-content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #000000;
      }
    }
  }

  @include respond(tab-port) {
    padding: 8rem 12rem;

    .feature-logo {
      width: 360px;
    }
  }

  @include respond(phone) {
    padding: 42px 16px 24px;

    .feature-logo {
      width: 80%;
    }
  
    .feature-items {
      display: flex;
      flex-direction: column;
      margin-top: 48px;
      
      .feature-item {
        max-width: 100%;
        margin: 48px 0 0;
      }
    }
  }
}
