.RequestAccessPage {
  display: grid;
  grid-template-columns: 66% 34%;
  min-height: 100vh;

  .left-panel,
  .right-panel {
    height: 100%;
  }
  
  .back-btn {
    position: absolute;
    top: 30px;
    left: 30px;
    border: 1px solid #A9B3BD;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%;
    
    .back-btn-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .left-panel {
    .left-panel-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    .header-logo {
      width: 300px;
      object-fit: contain;
    }

    .right-panel-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;

      .right-panel-title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #424242;
        text-align: left;
        margin: 0 0 39px;
      }

      .input-fields {
        display: flex;
        flex-direction: column;
        max-width: 400px;

        .input-fields-phone {
          display: flex;
          align-items: center;
          margin-bottom: 14px;

          .request-access-input {
            margin-left: 14px;
            flex: 1;
          }
        }
      }

      .right-panel-description {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: left;
        color: #57565A;
        margin: 28px 0;
      }
    
      .request-access-btn {
        background: #F31208;
        border-radius: 6px;
        color: white;
        width: 100%;
        text-transform: inherit;

        &.disabled {
          background: #47494C;
          color: #909193;

          &:hover, &:active {
            transform: none;
            box-shadow: none;
          }
        }
      }

      .privacy-policy-text {
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: #57565A;
        margin: 30px auto 0;

        .highlight {
          text-decoration-line: underline;
        }
      }
    }

    .switch-login-btn {
      background: #F31208;
      border-radius: 6px;
      color: white;
      width: 100%;
      text-transform: inherit;
    }
  }

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    min-height: unset;

    .left-panel {
      width: 100%;
      height: 350px;
    }

    .right-panel {
      height: unset;
      padding: 48px 16px;
      align-items: unset;
      margin-top: -120px;

      .header-logo {
        margin: 0 auto;
      }

      .right-panel-content {
        flex: unset;
        display: flex;
        flex-direction: column;
        justify-content: unset;
        margin: 20px 0;

        .right-panel-title {
          text-align: center;
          margin: 0 0 18px;
        }

        .input-fields {
          max-width: 100%;

          .input-fields-phone {
            .request-access-input {
              width: 100%;
            }
          }
        }
        
        .right-panel-description {
          text-align: center;
        }
      }
    }
  }

  @include respond(phone) {
    display: flex;
    flex-direction: column;
    min-height: unset;
    
    .left-panel {
      width: 100%;
      height: 350px;
    }

    .right-panel {
      height: unset;
      padding: 48px 16px;
      align-items: unset;
      margin-top: -120px;

      .header-logo {
        margin: 0 auto;
      }

      .right-panel-content {
        flex: unset;
        display: flex;
        flex-direction: column;
        justify-content: unset;
        margin: 20px 0;

        .right-panel-title {
          text-align: center;
          margin: 0 0 18px;
        }

        .input-fields {
          max-width: 100%;

          .input-fields-phone {
            .request-access-input {
              width: 100%;
            }
          }
        }
        
        .right-panel-description {
          text-align: center;
        }
      }
    }
  }
}