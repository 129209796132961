@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin center-horizontal-vertical {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERIES

/*
$breakpoint arguments:
- phone
- tab-port
- tab-land
- big-desktop
*/

@mixin respond($breakpoint) {
  // 600px
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }

  // 900px
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }

  // 1200px
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }

  // 1800px
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}
