.RequestConfirmedPage {
  display: flex;
  align-items: center;
  justify-content: right;
  min-height: 100vh;
  padding: 30px;

  .back-btn {
    position: absolute;
    top: 30px;
    left: 30px;
    border: 1px solid #A9B3BD;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%;
    
    .back-btn-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .header-panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding: 30px 109px;

    .header-logo {
      width: 270px;
      object-fit: contain;
    }
  }

  .main-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 450px;

    .request-confirmed-icon {
      width: 74px;
      object-fit: contain;
    }

    .main-panel-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      color: #424242;
      margin: 30px 0 0;
    }

    .main-panel-description {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #424242;
      margin: 30px 0;
    }
  
    .request-confirmed-btn {
      background: #F31208;
      border-radius: 6px;
      color: white;
      width: 100%;
      text-transform: inherit;
    }
  }

  @include respond(tab-port) {
    padding: 48px 16px;

    .header-panel {
      display: flex;
      justify-content: center;
      padding: 15px;
      top: 100px;
    }

    .main-panel {
      align-items: center;
      padding: 120px 20px 20px;
      max-width: 100%;

      .request-confirmed-icon {
        width: 74px;
      }

      .main-panel-title {
        text-align: center;
        margin: 24px 0 0;
      }

      .main-panel-description {
        text-align: center;
        margin: 24px 0;
      }
    }
  }

  @include respond(phone) {
    padding: 48px 16px;

    .header-panel {
      display: flex;
      justify-content: center;
      padding: 15px;
      top: 100px;
    }

    .main-panel {
      align-items: center;
      padding: 120px 20px 20px;
      max-width: 100%;

      .request-confirmed-icon {
        width: 74px;
      }

      .main-panel-title {
        text-align: center;
        margin: 24px 0 0;
      }

      .main-panel-description {
        text-align: center;
        margin: 24px 0;
      }
    }
  }
}