.TermsConditionsPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .terms-conditions-content {
    color: black;
    padding: 120px 50px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .terms-conditions-title {
      font-size: 70px;
      font-weight: bold;
      line-height: 1;
      margin: 0 0 100px;
    }

    .terms-conditions-address {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin: 0 0 20px;
    }

    .terms-conditions-description {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      
      ol {
        list-style: disc;
        margin-left: 20px;
      }
    }

    .terms-conditions-items {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .terms-conditions-item {
        display: flex;
        flex-direction: column;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        
        ol {
          list-style: disc;
          margin-left: 20px;
        }

        .terms-conditions-item-title {
          font-size: 36px;
          font-weight: bold;
          margin: 0 0 15px;
        }

        &:not(:first-child) {
          margin-top: 25px;
        }
      }
    }
  }

  @include respond(tab-port) {
  }

  @include respond(phone) {
    min-height: unset;

    .terms-conditions-content {
      padding: 100px 50px 30px;

      .terms-conditions-title {
        text-align: center;
      }
    }
  }
}