.footer {
  background-color: white;
  padding: 4rem 6rem;
  display: flex;
  flex-direction: column;

  .footer-menus {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .footer-menu-texts {
      display: flex;
      flex-direction: column;
      margin: 0 290px 0 0;

      .footer-logo {
        margin-bottom: 20px;
        width: 193px;
      }

      .footer-menu-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #616161;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .footer-menu-items {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      margin-left: auto;
      width: fit-content;

      .footer-menu-item-columns {
        display: flex;
        flex-direction: column;
        min-width: 180px;

        &:not(:last-child) {
          margin-right: 15px;
        }
      }

      .footer-menu-item {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #616161;
        text-decoration: none;

        &:not(:last-child) {
          margin-bottom: 18px;
        }

        &.main {
          color: black;
          font-weight: bold;
        }
      }
    }
  }

  .footer-payment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    span {
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
      line-height: 30px;
      color: #616161;
    }

    .footer-payment-cards {
      display: flex;
      flex-direction: row;
      align-items: center;

      .footer-payment-image {
        width: 50px;
        object-fit: contain;
        margin: 0 5px;
      }
    }

    .footer-payment-crypto {
      display: flex;
      flex-direction: row;
      align-items: center;

      .footer-payment-image {
        width: 85px;
        object-fit: contain;
        margin: 0 5px;
      }
    }
  }

  .footer-border {
    border: 1px solid #767676;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 30px 0;
  }

  .footer-follow {
    display: flex;
    align-items: center;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: #616161;
    }

    .footer-social-link {
      margin: 0 10px;

      .footer-social-image {
        height: 18px;
        object-fit: contain;
      }
    }
  }

  .footer-privacy {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .footer-privacy-text {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      color: #616161;
    }

    .footer-privacy-terms {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;

      a {
        color: #616161;
      }
    }

    .footer-partner {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin: 0 20px;
      
      .footer-partner-image {
        height: 36px;
        object-fit: contain;
      }

      .footer-partner-separator {
        background-color: black;
        margin: 0 14px;
        width: 1px;
        height: 25px;
      }

      .footer-partner-text {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        line-height: 1;
        color: #000000;
      }
    }

    .footer-language {
      display: flex;
      align-items: center;

      .footer-language-text {
        border: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #616161;
      }
    }
  }

  @include respond(tab-port) {
    padding: 48px 16px;

    .footer-menus {
      display: flex;
      flex-direction: column;

      .footer-menu-items {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin: 20px 0 0;
        
        .footer-menu-item-columns:not(:last-child) {
          margin-bottom: 24px;
        }  
      }
    }
  }

  @include respond(phone) {
    padding: 48px 16px;

    .footer-menus {
      display: flex;
      flex-direction: column;

      .footer-menu-texts {
        margin: 0 0 16px;
      }

      .footer-menu-items {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin: 20px 0 0;
        
        .footer-menu-item-columns:not(:last-child) {
          margin-bottom: 24px;
        }  
      }
    }

    .footer-payment {
      display: flex;
      flex-direction: column;

      .footer-payment-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
  
        .footer-payment-image {
          width: 50px;
          object-fit: contain;
          margin: 5px;
        }
      }
  
      .footer-payment-crypto {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .footer-payment-image {
          width: 85px;
          object-fit: contain;
          margin: 10px 0 0;
        }
      }
    }
    
    .footer-follow {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 18px;
      }

      .footer-social-link {
        margin: 0 8px;

        .footer-social-image {
          height: 18px;
        }
      }
    }

    .footer-privacy {
      display: flex;
      flex-direction: column;

      .footer-partner {
        margin: 20px 0;

        .footer-partner-image {
          height: 24px;
        }

        .footer-partner-separator {
          height: 16px;
          margin: 0 10px;
        }

        .footer-partner-text {
          font-size: 12px;
        }
      }
    }

    .footer-language {
      .footer-language-text {
        font-size: 14px;
      }
    }
  }
}
