.section-tours {
  position: relative;

  .tours-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    max-width: 520px;

    .tours-title {
      font-style: normal;
      font-weight: 700;
      font-size: 62px;
      line-height: 130%;
      color: #FFFFFF;
      margin-bottom: 18px;
    }

    .tours-description {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 133%;
      color: #FFFFFF;
    }

    .tours-request-btn {
      color: white;
      background: #F31208;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      padding: 14px 24px;
      text-transform: none;
      max-width: 350px;
      margin-top: 20px;
    
      &:hover {
        background: #eb261c;
      }
    
      &:active {
        background: #c5150c;
      }
    }

    .tours-login {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      color: #FFFFFF;
      margin-top: 64px;

      a {
        color: white;

        &:visited, &:hover {
          color: white;
        }
      }
    }
  }

  .carousel-root {
    height: 700px;

    img {
      height: 700px;
      object-fit: cover;
    }
  }

  @include respond(tab-port) {
    .tours-content {
      max-width: 520px;
      padding: 20px 30px;

      .tours-title {
        font-size: 40px;
        margin-bottom: 14px;
      }

      .tours-description {
        font-size: 20px;
      }
      
      .tours-request-btn {
        font-size: 20px;
        padding: 10px 12px;
        max-width: 300px;
        margin-top: 14px;
      }

      .tours-login {
        font-size: 20px;
        margin-top: 36px;
      }
    }
  }

  @include respond(phone) {
    .tours-content {
      max-width: 100%;
      padding: 33px 19px;

      .tours-title {
        font-size: 36px;
        margin-bottom: 18px;
      }

      .tours-description {
        font-size: 20px;
      }
      
      .tours-request-btn {
        max-width: 100%;
        margin-top: 50px;
      }

      .tours-login {
        font-size: 20px;
        margin: 32px auto 0;
      }
    }

    .carousel-root {
      height: 600px;

      img {
        height: 600px;
        object-fit: cover;
      }
    }
  }
}
