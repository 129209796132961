.section-leadership {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 12rem;

  .leadership-title {
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 130%;
    color: #9F9F9F;
    text-align: center;
  }

  .leadership-items {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    
    .leadership-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px 15px;
      max-width: 350px;

      .leadership-item-image {
        width: 190px;
        height: 190px;
      }

      .leadership-item-name {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        color: #FFFFFF;
        text-align: center;
        margin-top: 10px;
      }

      .leadership-item-role {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
        color: #9F9F9F;
        text-align: center;
        margin-top: 10px;
      }

      .leadership-item-content {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #9F9F9F;
        margin-top: 13px;

        .highlight {
          font-style: italic;
          color: white;
        }
      }
    }
  }

  @include respond(tab-port) {
    padding: 48px 20px 48px;
  }

  @include respond(phone) {
    padding: 48px 20px 48px;

    .leadership-title {
      font-size: 36px;
      line-height: 45px;
    }

    .leadership-items {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .leadership-item {
        margin: 30px 0 0;
        max-width: 100%;

        .leadership-item-image {
          width: 190px;
          height: 190px;
        }

        .leadership-item-name {
          font-size: 24px;
          margin-top: 6px;
          text-align: center;
        }

        .leadership-item-role {
          font-size: 24px;
          margin-top: 6px;
          text-align: center;
        }

        .leadership-item-content {
          font-size: 18px;
          margin-top: 15px;
          text-align: center;
        }
      }
    }
  }
}
