.btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.8rem 4rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    font-size: 16px;

    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-0.1rem);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.1) scaleY(1.1);
      opacity: 0;
    }
  }

  &:active {
    transform: translateY(-0.1rem);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;
    &::after {
      background-color: $color-white;
    }
  }

  &--green {
    background-color: $color-primary;
    color: $color-white;
    &::after {
      background-color: $color-primary;
    }
  }

  &:after {
    content: " ";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
}

.move-in--bottom {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-text {
  font-size: $default-font-size;
  color: $color-primary;
  display: inline-block;
  border: none;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid $color-primary;
  padding: 0.3rem;
  transition: all 0.2s;
  background-color: transparent;

  &:hover {
    background-color: $color-primary;
    color: $color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
    transform: translateY(-0.1rem);
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
    transform: translateY(0);
  }
}
