.navigation {
  position: fixed;
  bottom: 0;
  right: 180px;
  padding: 20px;
  background: #F3F3F3;
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
  z-index: 100;

  .navigation-top {
    width: 12px;
    object-fit: contain;
  }

  @include respond(tab-port) {
    bottom: 0;
    right: 10px;
  }

  @include respond(phone) {
    bottom: 0;
    right: 10px;
  }
}
