.VerificationPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px;

  .back-btn {
    position: absolute;
    top: 30px;
    left: 30px;
    border: 1px solid #A9B3BD;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%;
    
    .back-btn-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .header-panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding: 30px 109px;

    .header-logo {
      width: 270px;
      object-fit: contain;
    }
  }

  .main-panel {
    display: flex;
    flex-direction: column;
    padding: 159px 30px 30px 70px;

    .main-panel-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #424242;
    }

    .main-panel-description {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #57565A;
      margin-top: 24px;
    }

    .input-layout {
      max-width: 350px;
      margin-top: 40px;
    }

    .input-fields {
      display: flex;
      
      .passcode-input {
        background: #F6F7F8;
        border-radius: 4px;
        width: 60px;
        height: 79px;
        padding: 2px;
        text-align: center;

        &:not(:first-child) {
          margin: 0 0 0 8px;
        }
      }
    }

    .resend-layout {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 40px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #57565A;
      }

      a {
        color: #57565A;
      }
    }

    .support-layout {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #57565A;
      margin-top: 70px;
      
      a {
        color: #57565A;
      }
    }
  }

  @include respond(tab-port) {
    padding: 48px 16px;

    .header-panel {
      display: flex;
      justify-content: center;
      padding: 15px;
      top: 100px;
    }

    .main-panel {
      align-items: center;
      padding: 160px 20px 20px;

      .main-panel-title {
        text-align: center;
      }

      .main-panel-description {
        text-align: center;
      }
      
      .input-fields {
        display: flex;
        
        .passcode-input {
          width: 50px;
          height: 59px;

          &:not(:first-child) {
            margin: 0 0 0 6px;
          }
        }
      }

      .resend-layout {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span:first-child {
          margin-bottom: 20px;
        }
      }

      .support-layout {
        margin-top: 30px;
        text-align: center;
      }
    }
  }

  @include respond(phone) {
    padding: 48px 16px;

    .header-panel {
      display: flex;
      justify-content: center;
      padding: 15px;
      top: 100px;
    }

    .main-panel {
      align-items: center;
      padding: 160px 20px 20px;

      .main-panel-title {
        text-align: center;
      }

      .main-panel-description {
        text-align: center;
      }
      
      .input-fields {
        display: flex;
        
        .passcode-input {
          width: 50px;
          height: 59px;

          &:not(:first-child) {
            margin: 0 0 0 6px;
          }
        }
      }

      .resend-layout {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span:last-child {
          margin-top: 20px;
        }
      }

      .support-layout {
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}