@import "../sections/about";
@import "../sections/feature";
@import "../sections/tours";
@import "../sections/leadership";
@import "../sections/stories";
@import "../sections/contact";

.MainPage {
  display: flex;
  flex-direction: column;
}