// COLORS
$color-primary: #55c57a;
$color-primary-light: #55c57a;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT CONTROL
$default-font-size: 1.6rem;
$normal-font-size: 1.4rem;
$medium-font-size: 3rem;
$big-font-size: 5rem;

// GRID background
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;

// OTHER

$shadow-default: 0 1.5rem 4rem rgba($color-black, 0.15);
$shadow-light: 0.5rem 1rem 2rem rgba($color-black, 0.2);
$shadow-link: 0 1rem 2rem rgba($color-black, 0.4);

$border-radius-small: 0.2rem;
$border-radius-default: 0.3rem;
$bottom-border-green: 3px solid $color-primary;
$bottom-border-green-thick: 5px solid $color-primary;
$bottom-border-orange: 3px solid $color-secondary-dark;
