.header {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  z-index: 200;

  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;

  &.scroll {
    opacity: 0;
  }

  .header-logo {
    cursor: pointer;
    height: 60px;
    object-fit: contain;
  }

  .header-navigation {
    width: 36px;
    object-fit: contain;
    cursor: pointer;
  }

  @include respond(tab-port) {
    padding: 14px 20px;
    
    .header-logo {
      height: 50px;
    }
  }

  @include respond(phone) {
    padding: 20px 16px;

    .header-logo {
      height: 40px;
    }
  }
}

.navigation-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 360px;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  background: #ececec;
  border: 1px solid #b9b9b9;
  z-index: 250;
  transition: transform .5s linear;
  overflow-y: auto;

  &.visible {
    transform: translate(0, 0);
  }

  &.hidden {
    transform: translate(100%, 0);
  }

  .nav-logo {
    width: 200px;
    object-fit: contain;
    margin-bottom: 35px;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #c4c4c4;
    padding-top: 20px;

    .nav-login {
      background: #f31208;
      border-radius: 4px;
      color: white;
      width: 100%;
      text-transform: inherit;
    }

    .nav-register {
      background: transparent;
      border: 1px solid black;
      border-radius: 4px;
      color: #f31208;
      width: 100%;
      text-transform: inherit;
      margin-bottom: 90px;
    }

    .nav-or {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: black;
      margin: 20px 0;
    }

    .nav-item {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: black;
      padding: 20px 0;
      border-top: 1px solid #C4C4C4;
      width: 100%;
      text-decoration: none;

      &:last-child {
        border-bottom: 1px solid #C4C4C4;
      }
    }
  }

  @include respond(phone) {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
  }
}
