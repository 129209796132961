.dropdown {
  width: 120px;
  border: 1px solid #BDBDBD;
  background: white;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px 10px;
  
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1D2026;
  
  & > div:first-of-type {
    border: 0;
    height: 100%;
  }

  & > div:nth-of-type(2) {
    left: -1px;
    width: calc(100% + 2px);
  }

  .dropdown-content__control {
    border: 0;
    box-shadow: none;
  }

  .attribute-dropdown-content__menu {
    padding: 0;
  }

  .dropdown-content__indicator-separator {
    background-color: transparent;
  }

  .dropdown-content__indicator {
    svg {
      fill: black;
    }
  }
}
