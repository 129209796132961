.RegisterPage {
  display: grid;
  grid-template-columns: 66% 34%;
  min-height: 100vh;

  .left-panel,
  .right-panel {
    height: 100%;
  }
  
  .back-btn {
    position: absolute;
    top: 30px;
    left: 30px;
    border: 1px solid #A9B3BD;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 50%;
    
    .back-btn-icon {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  .left-panel {
    background: #FFFFFF;
    padding: 110px 70px 30px;

    display: flex;
    flex-direction: column;

    .left-panel-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #424242;
      margin-bottom: 24px;
    }

    .account-types {
      display: flex;
      flex-direction: column;

      .account-types-title {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #57565A;
      }

      .account-types-icon {
        width: 20px;
        object-fit: contain;
        margin-right: 12px;
      }

      .account-types-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        background: #F31208;
        border: 1px solid #F31208;
        box-sizing: border-box;
        border-radius: 6px;
        
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 100%;
        color: #FFFFFF;
        text-transform: inherit;
        max-width: 420px;

        &.outline {
          background: #FFFFFF;
          border: 1px solid #F31208;
          font-weight: 700;
          color: #F31208;
        }

        &:not(:first-child) {
          margin-top: 20px;
        }

        &:nth-child(1) {
          margin-top: 179px;
        }
      }
    }

    .business-owner {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 20px;
      }

      .owner-title {
        font-style: italic;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #57565A;
      }

      .owner-description {
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #57565A;
        margin-top: 6px;
      }
    }
  }

  .right-panel {
    background: #F1F1F1;
    display: flex;
    flex-direction: column;
    padding: 30px;

    .right-panel-logo {
      width: 60%;
      object-fit: contain;
      margin: 0 auto;
    }

    .right-panel-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin: 20px 0;
    }

    .right-panel-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #424242;
    }

    .login-btn {
      background: #F31208;
      border-radius: 6px;
      font-weight: 700;
      max-width: 420px;
      color: white;
      text-transform: inherit;
      margin-top: 30px;
    }
    
  }

  @include respond(tab-port) {
    display: grid;
    grid-template-columns: 60% 40%;

    .left-panel {
      padding: 122px 30px 20px;
    }
  }

  @include respond(phone) {
    display: flex;
    flex-direction: column;

    .left-panel {
      order: 2;
      padding: 20px;
      text-align: center;

      .business-owner {
        text-align: left;
      }
    }

    .right-panel {
      order: 1;
      padding: 100px 20px 20px;
      text-align: center;
    }
  }
}