.section-contact {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 12rem;

  .contact-title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    color: #FFFFFF;
  }

  .contact-phone {
    display: flex;
    align-items: center;
    margin: 13px 0;

    .phone-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    .phone-number {
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 140%;
      color: white;
      text-align: center;
    }
  }

  .contact-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: white;
  }

  @include respond(tab-port) {
    padding: 48px 16px;
  }

  @include respond(phone) {
    padding: 48px 16px;

    .contact-title {
      font-size: 24px;
    }

    .contact-phone {
      .phone-number {
        font-size: 24px;
      }
    }

    .contact-text {
      font-size: 18px;
    }
  }
}
