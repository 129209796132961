.section-about {
  background-image: url("../../assets/images/about.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 30rem 10rem 5rem;

  .about-logo {
    width: 306px;
    object-fit: contain;
  }

  .about-title {
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 62px;
    line-height: 100%;
    margin-top: 30px;
  }

  .about-sub-title {
    color: white;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 100%;
  }

  .about-content {
    color: white;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
    margin-top: 30px;
    max-width: 600px;
  }

  .about-request-access {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    .about-request-phone {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .about-request-input {
      margin: 0 0 0 14px;
    }

    .about-request-btn {
      color: white;
      background: #F31208;
      border-radius: 6px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      padding: 14px 24px;
      margin: 0 0 0 14px;
      text-transform: none;
    
      &:hover {
        background: #eb261c;
      }
    
      &:active {
        background: #c5150c;
      }
    }
  }

  .about-users {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    margin-top: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .about-request-sms {
      width: 20px;
      object-fit: contain;
      margin: 0 10px;
    }
  }

  @include respond(tab-port) {
    padding: 20rem 8rem 4rem;

    .about-request-access {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;

      .about-request-phone {
        .about-request-input {
          flex: 1;
          margin: 0 0 0 14px;
        }
      }

      .about-request-input {
        margin: 16px 0 0;
        width: 100%;
      }

      .about-request-btn {
        margin: 14px 0 0;
        width: 100%;
      }
    }

    .about-users {
      display: flex;
      flex-direction: column;
    }
  }

  @include respond(phone) {
    padding: 140px 16px 45px;

    .about-logo {
      width: 100%;
    }

    .about-title {
      font-size: 40px;
      margin-top: 35px;
    }

    .about-sub-title {
      font-size: 25px;
    }

    .about-content {
      font-size: 18px;
      margin-top: 20px;
      max-width: 100%;
    }
  
    .about-request-access {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;

      .about-request-phone {
        .about-request-input {
          flex: 1;
          margin: 0 0 0 14px;
        }
      }
      
      .about-request-input {
        margin: 16px 0 0;
        width: 100%;
      }

      .about-request-btn {
        margin: 14px 0 0;
        width: 100%;
      }
    }

    .about-users {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      line-height: 32px;

      .about-request-sms {
        display: none;
      }
    }
  }
}